import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const mixpanel = useMixpanel();
  const data = useStaticQuery(graphql`
    query {
      apple: file(relativePath: { eq: "apple-app-store.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      google: file(relativePath: { eq: "google-play-badge.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const navigateToApple = event => {
    event.preventDefault();
    mixpanel.track('Click App Store');
    window.location.href = 'https://apps.apple.com/us/app/dialdoge/id6503260861';
  }

  const navigateToGoogle = event => {
    event.preventDefault();
    mixpanel.track('Click Google Play');
    window.location.href = 'https://play.google.com/store/apps/details?id=com.venepe.dialdoge';
  }

  return (
    <div>
      <div style={{cursor: 'pointer'}} onClick={navigateToApple}>
        <Img style={{zIndex:'-10'}} fixed={data.apple.childImageSharp.fixed} alt={'Apple App Store Badge'} />
      </div>
      <div style={{cursor: 'pointer'}} onClick={navigateToGoogle}>
        <Img style={{zIndex:'-10'}} fixed={data.google.childImageSharp.fixed} alt={'Google Play Store Badge'} />
      </div>
    </div>
  )
}

export default Image
