import React, { Component } from 'react';
import { withMixpanel } from 'gatsby-plugin-mixpanel';
import Slider from 'react-slick';
import YouTube from 'react-youtube';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import AppStoreImage from '../components/images/AppStoreImage';
import HeroImage from '../components/images/HeroImage';
import BrittanyImage from '../components/images/BrittanyImage';
import DobermanImage from '../components/images/DobermanImage';
import PoodleImage from '../components/images/PoodleImage';
import SvgCharts from '../svg/SvgCharts';

class Index extends Component {
    render() {
      const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        // autoplay: true,
        // autoplaySpeed: 2200,
      };

      const videoStyle = {
        height: '500px',
        width: '100%',
      };

      const videoOptions = {
        height: '500px',
        width: '100%',
        playerVars: {
          autoplay: 0,
        },
      };

      return (
        <Layout>
          <SEO title="A New Breed of Consultant" />
          <section className="pt-20 md:pt-40">
            <div className="container mx-auto px-8 lg:flex">
              <div className="lg:text-left lg:w-1/2">
                <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
                  I'm listening
                </h1>
                <p className="text-xl lg:text-2xl mt-6 font-light">
                  More than an assistant, he's a companion
                </p>
                <div className="mt-2">
                  <AppStoreImage/>
                </div>
              </div>
              <div className="lg:w-1/2 mt-6">
                <HeroImage />
              </div>
            </div>
          </section>
          <section className="container mx-auto my-20 py-24 rounded-lg text-center">
            <div id="services"></div>
            <h3 className="text-5xl font-semibold">A New Breed of Consultant</h3>
              <SplitSection
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Research Analyst
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Conducts research to gather information and insights that drive innovation and support business development.
                    </p>
                  </div>
                }
                secondarySlot={<PoodleImage />}
              />
              <SplitSection
                reverseOrder
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Risk Analyst
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Identifies and assesses potential risks to the organization, developing strategies to mitigate and manage those risks.
                    </p>
                  </div>
                }
                secondarySlot={<DobermanImage />}
              />
              <SplitSection
                primarySlot={
                  <div className="lg:pr-32 xl:pr-48">
                    <h3 className="text-3xl font-semibold leading-tight">
                      Financial Analyst
                    </h3>
                    <p className="mt-8 text-xl font-light leading-relaxed">
                      Responsible for managing the financial health of the organization, including budgeting, forecasting, and financial reporting.
                    </p>
                  </div>
                }
                secondarySlot={<BrittanyImage />}
              />
          </section>
          <section id='video' className="justify-center mx-auto my-20 py-24 rounded-lg text-center lg:w-1/2">
            <h3 className="text-5xl my-20 font-semibold">Trained to Listen</h3>
            <Slider {...settings}>
              <YouTube style={videoStyle} opts={videoOptions} videoId="L1EuCxKQ-MM" />
              <YouTube style={videoStyle} opts={videoOptions} videoId="hap5haGBFuw" />
              <YouTube style={videoStyle} opts={videoOptions} videoId="IZVE8kZymLg" />
            </Slider>
          </section>
          <section id="testimonials" className="py-20 lg:py-40">
            <div className="container mx-auto">
              <LabelText className="mb-8 text-gray-600 text-center">What users are saying</LabelText>
              <div className="flex flex-col md:flex-row md:-mx-3">
                {customerData.map(customer => (
                  <div key={customer.customerName} className="flex-1 px-3">
                    <CustomerCard customer={customer} />
                  </div>
                ))}
              </div>
            </div>
          </section>
          <section id='download' className="container mx-auto my-20 py-24 rounded-lg text-center lg:w-1/4">
            <h3 className="text-5xl font-semibold leading-none my-4">Just DialDoge</h3>
            <div>
              <AppStoreImage/>
            </div>
          </section>
        </Layout>
      );
    }
};

export default withMixpanel()(Index);
